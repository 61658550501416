import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount as useWalletAccount, WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider } from 'ethers';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ExistingCollectionDetail from './details/Minted/ExistingCollectionDetail';
import LazyMintCollectionDetail from './details/MintOnPurchase/LazyMintCollectionDetail';

import UserLayout from '~/components/app-layout/user-layout';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import HomeBtn from '~/components/home-btn';
import LoaderCenter from '~/components/loader-center';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { Collection, useGetCollectionQuery, useRemoveCollectionMutation } from '~/graphql/member/types';
import CollectionDetailInfo from '~/pages/collection/CollectionDetail/details/CollectionDetailInfo';
import { CollectionInfos, CollectionTabEnum } from '~/types/my-shop';
import { notFound } from '~/utils/not-found';

export const CollectionTab = (t: TFunction) => ({
  [CollectionTabEnum.ISSUE]: {
    value: 0,
    label: t('list_issued_nfts'),
  },
  [CollectionTabEnum.LAZY_MINT]: {
    value: 1,
    label: t('images_for_new_issue_nft'),
  },
});
export const CollectionDetailTab = (t: TFunction) => ({
  [CollectionInfos.BASIC]: {
    value: 0,
    label: t('my_shop.basic'),
  },
  [CollectionInfos.MINTED_NFT]: {
    value: 1,
    label: t('minted_nft_title').toLocaleUpperCase(),
  },
  [CollectionInfos.MINT_ON_PURCHASE]: {
    value: 2,
    label: t('mint_on_purchase').toLocaleUpperCase(),
  },
});

const useStyles = makeStyles()(() => ({
  tabs: {
    flex: 1,
    borderBottom: 1,
    borderColor: 'divider',
  },
}));

const CollectionDetail = () => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { classes } = useStyles();
  const { collectionId } = useParams();
  const { account } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();

  const { data: collectionResponse } = useGetCollectionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      collectionUuid: collectionId || '',
    },
    onError: (err) => {
      notFound(Object.values(err)?.[0]?.[0]?.extensions?.code, navigate);
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collectionData, setCollectionData] = useState<Collection>();
  const [openWalletConnector, setOpenWalletConnector] = useState(false);
  const [tab, setTab] = useState(CollectionDetailTab(t)[CollectionInfos.BASIC].value);
  const [openRemoveCollectionDialog, setOpenRemoveCollectionDialog] = useState(false);

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [removeCollection] = useRemoveCollectionMutation();

  const onCloseRemoveCollectionDialog = useCallback(
    async (params?: { isSubmitted?: boolean }) => {
      setOpenRemoveCollectionDialog(false);
      if (params?.isSubmitted) {
        navigate(AppRouteEnum.Collection);
      }
    },
    [navigate]
  );

  const handleCloseWalletConnector = () => {
    setOpenWalletConnector(false);
  };

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, value: any) => {
    setTab(value);
    navigate(`${location.pathname}?tab=${value}`);
  };

  const onConfirmRemoveCollection = async () => {
    try {
      await removeCollection({
        variables: {
          collectionUuid: collectionId || '',
        },
      });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const onOpenRemoveCollectionDialog = useCallback(() => {
    setOpenRemoveCollectionDialog(true);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (collectionResponse?.getCollection) {
          const currentCollection: Collection = JSON.parse(JSON.stringify(collectionResponse.getCollection));
          const provider = new JsonRpcProvider(supportedNetworks?.[currentCollection.network]?.rpcUrl);
          const contractWithoutSigner = ERC721G__factory.connect(currentCollection.contractAddress, provider as any);
          const owner = await contractWithoutSigner.owner();

          currentCollection.ownerAddress = owner;
          setCollectionData(currentCollection);

          const urlParams = new URLSearchParams(location.search);
          const tabFromUrl = urlParams.get('tab');
          const tabNumber = Number(tabFromUrl);
          if (tabFromUrl) {
            if (!!currentCollection?.isErc721 && tabNumber === 2) {
              navigate(location.pathname);
              setTab(0);
            } else {
              setTab(tabNumber);
            }
          }
          setIsLoading(false);
        }
      } catch (err: any) {
        enqueueSnackbar(t('my_shop.message.error'), { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, supportedNetworks, collectionResponse?.getCollection, t, enqueueSnackbar]);

  const renderContent = useMemo(() => {
    switch (tab) {
      case CollectionDetailTab(t)[CollectionInfos.BASIC].value:
        return (
          <CollectionDetailInfo
            collectionData={collectionData}
            onOpenRemoveCollectionDialog={onOpenRemoveCollectionDialog}
          />
        );
      case CollectionDetailTab(t)[CollectionInfos.MINT_ON_PURCHASE].value:
        return <LazyMintCollectionDetail collectionData={collectionData!} />;
      case CollectionDetailTab(t)[CollectionInfos.MINTED_NFT].value:
        return <ExistingCollectionDetail collectionData={collectionData!} />;
      default:
        return <LoaderCenter />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, collectionData, t]);
  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Link
          component={RouterLink}
          to={AppRouteEnum.Collection}
          color="text.primary"
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {t('collections')}
        </Link>
        <Typography style={{ wordBreak: 'break-all' }} color="text.secondary">
          {collectionData?.name || '...'}
        </Typography>
      </Breadcrumbs>
      <Box width="100%" display="flex" flexDirection="column">
        {isLoading ? (
          <LoaderCenter />
        ) : (
          <>
            <Box sx={{ flex: 1, borderBottom: 1, borderColor: 'divider', margin: '0 24px 24px 0', width: '100%' }}>
              <Tabs
                variant={isSmScreen ? 'fullWidth' : undefined}
                value={tab}
                onChange={handleChangeTab}
                className={classes.tabs}
              >
                {Object.values(CollectionDetailTab(t))
                  .filter((tab) => !(collectionData?.isErc721 && tab.value === 2))
                  .map((item) => (
                    <Tab key={item.value} label={item.label} />
                  ))}
              </Tabs>
            </Box>
            {renderContent}
          </>
        )}
      </Box>
      <WalletConnectorDialog open={openWalletConnector} onClose={handleCloseWalletConnector} />
      <ConfirmationDialog
        open={openRemoveCollectionDialog}
        content={t('my_shop.message.confirm_delete_collection')}
        title={t('collection_screen.delete_collection')}
        onConfirm={onConfirmRemoveCollection}
        onClose={onCloseRemoveCollectionDialog}
      />
    </UserLayout>
  );
};

export default CollectionDetail;
