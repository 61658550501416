import { useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { Currency, GetMeDocument, useUpdateOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '16px',
  },
}));

const schemaCurrency = yup.object({
  baseCurrency: yup.mixed<Currency>().required(),
});

export interface FormBaseCurrencyValues extends yup.InferType<typeof schemaCurrency> {}

const BaseCurrency = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { selectedOrganization } = useAccount();
  const { showError, showSuccess } = useNotify();
  const [editable] = useCheckPermissions([SCREEN_PERMISSION.SETTING.PAYMENT.EDIT]);

  const [updateOrganizationMutation] = useUpdateOrganizationMutation({ refetchQueries: [GetMeDocument] });

  const {
    control: controlCurrency,
    reset: resetCurrency,
    handleSubmit: handleSubmitCurrency,
    formState: { errors: errorsCurrency, isSubmitting: isSubmittingCurrency, dirtyFields: currencyDirtyFields },
  } = useForm<FormBaseCurrencyValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      baseCurrency: selectedOrganization.currencySetting?.baseCurrency || Currency.Jpy,
    },
    resolver: yupResolver(schemaCurrency),
  });

  const baseCurrency = useWatch({
    name: 'baseCurrency',
    control: controlCurrency,
  });

  const currencyOptions = useMemo(
    () => (
      <MenuItem key={Currency.Jpy} value={Currency.Jpy} disabled={baseCurrency === Currency.Jpy}>
        {Currency.Jpy}
      </MenuItem>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const isDirtyCurrency = !!Object.keys(currencyDirtyFields).length;

  const onSubmitCurrency = async (data: FormBaseCurrencyValues) => {
    try {
      await updateOrganizationMutation({
        variables: {
          input: {
            currencySetting: {
              baseCurrency: data.baseCurrency || Currency.Jpy,
            },
          },
        },
      });
      resetCurrency({ baseCurrency: data.baseCurrency });
      showSuccess('my_shop.message.update_successful');
    } catch (err: any) {
      showError(err);
    }
  };
  return (
    <CustomCardTable
      cardTitle={t('my_shop.payment_methods.base_currency')}
      cardContent={
        <Box className={classes.wrapper}>
          <Controller
            name="baseCurrency"
            control={controlCurrency}
            render={({ field }) => (
              <TextField
                select
                fullWidth
                variant="outlined"
                disabled={!editable || isSubmittingCurrency}
                label={t('my_shop.payment_methods.base_currency')}
                error={!!errorsCurrency.baseCurrency?.message}
                helperText={t(errorsCurrency.baseCurrency?.message as any)}
                {...field}
              >
                {currencyOptions}
              </TextField>
            )}
          />
          <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%', marginTop: '24px' }}>
            <Button
              variant="contained"
              disabled={!editable || isSubmittingCurrency || !isDirtyCurrency}
              endIcon={isSubmittingCurrency && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmitCurrency(onSubmitCurrency)}
            >
              {t('update')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default BaseCurrency;
