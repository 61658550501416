import { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useIpfsGateways } from '@gu-corp/react-ipfs-media';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import EditNameDialog, { IEditNFTNameField } from '~/components/dialog/edit-nft-name-dialog';
import MintedNFTDialog from '~/components/dialog/minted-nft-dialog';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { CHIP_COLOR } from '~/constants/common';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { VIEW_MODE, ContextAPIEnum } from '~/enum/common';
import {
  Currency,
  GetMyShopDocument,
  ListMyShopCollectionTokensDocument,
  MyShopCollectionsTokensQueryKey,
  QueryOperator,
  useListMyShopCollectionTokensQuery,
  useSetMyShopCollectionTokenMutation,
  useUpdateMyShopCollectionMutation,
} from '~/graphql/member/types';
import { _SubgraphErrorPolicy_, TokenQuery, useTokensLazyQuery } from '~/graphql/subgraph/types';
import { useNotify } from '~/hooks/useNotify';
import {
  ICollectionInfo,
  useShopCollectionDetail,
} from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import EditPriceDialog from '~/pages/my-shop/shop-detail/components/EditPriceDialog';
import {
  collectionStatus,
  renderSelectEditInputCell,
} from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { INftInfo, MetadataJson, NFT_STATUS, STATUS } from '~/types/my-shop';
import { priceWithSymbol, verifyOrderKey, verifySortKey } from '~/utils/common';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

interface ExistingNFTListProps {
  tokenList: INftInfo[] | undefined;
  collectionInfo: ICollectionInfo | undefined;
  onOpenAddNFT: () => void;
  setTokenList: Dispatch<SetStateAction<INftInfo[] | undefined>>;
}

export interface MintedNFTInDataGrid {
  id: string;
  url?: string;
  name?: string;
  uuid?: string;
  price?: number;
  nameJa?: string;
  status?: STATUS;
  tokenId?: string;
  createdAt?: string;
  description?: string;
  metadataUrl?: string;
  ownerAddress?: string;
  collectionUuid: string;
  estimatedUsdPrice?: number;
  metadataContent?: MetadataJson;
  [MyShopCollectionsTokensQueryKey.Name]: string;
  [MyShopCollectionsTokensQueryKey.Order]: number;
  [MyShopCollectionsTokensQueryKey.NameJa]: string;
  [MyShopCollectionsTokensQueryKey.Status]: STATUS;
  [MyShopCollectionsTokensQueryKey.TokenId]: string;
  [MyShopCollectionsTokensQueryKey.TokenName]: string;
  [MyShopCollectionsTokensQueryKey.Price]: number | null;
  [MyShopCollectionsTokensQueryKey.TokenMintTime]: string;
}

interface IInitInfo {
  [key: string]: MintedNFTInDataGrid;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiSelect-standard': {
      backgroundColor: 'transparent!important',
    },
    '.disabled': {
      opacity: 0.5,
    },
  },
  noPriceSet: {
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ExistingNFTList: FC<ExistingNFTListProps> = (props) => {
  const { collectionInfo, onOpenAddNFT } = props;
  const { supportedNetworks } = useSupportedNetworks();
  const { data: myShop, exchangeRate } = useShopDetail();
  const { data: myShopCollection, loading: loadingMyShopCollection } = useShopCollectionDetail();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { id: shopId } = useParams();
  const { ipfsGateways } = useIpfsGateways();
  const { show } = usePaymentMethodRequired();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const [rows, setRows] = useState<MintedNFTInDataGrid[]>([]);

  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedToken, setSelectedToken] = useState<MintedNFTInDataGrid>();
  const [loadingTokensList, setLoadingTokensList] = useState<boolean>(true);
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState<boolean>(false);

  const [openEditNFTNameDialog, setOpenEditNFTNameDialog] = useState<boolean>(false);

  const [tokensQuery, setTokensQuery] = useState({
    searchText: '',
    sortBy: verifySortKey(
      MyShopCollectionsTokensQueryKey,
      myShopCollection?.sortBy,
      MyShopCollectionsTokensQueryKey.TokenMintTime
    ),
    orderBy: verifyOrderKey(myShopCollection?.orderBy),
    where: {
      fields: [
        {
          operator: QueryOperator.Equals,
          value: [myShopCollection?.uuid ?? ''],
          key: MyShopCollectionsTokensQueryKey.MyshopCollectionUuid,
        },
      ],
    },
  });

  const updateTokensQuery = (newValue: any) => {
    setLoadingTokensList(true);
    setTokensQuery((value: any) => ({ ...value, ...newValue }));
  };

  const [getTokens, { loading: fetchingTokens }] = useTokensLazyQuery();
  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [setMyShopCollectionToken] = useSetMyShopCollectionTokenMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionTokensDocument],
  });
  useListMyShopCollectionTokensQuery({
    variables: tokensQuery,
    fetchPolicy: 'cache-and-network',
    skip: !myShopCollection?.uuid || typeof exchangeRate !== 'number',
    onCompleted: async (data) => {
      setLoadingTokensList(true);
      const myShopCollectionTokens = data.listMyShopCollectionTokens.items;
      if (!myShopCollectionTokens) {
        setLoadingTokensList(false);
        return;
      }
      const initInfos: IInitInfo = {};
      const contractAddress = collectionInfo?.contractAddress;
      const tokenIdsList =
        myShopCollectionTokens?.reduce((result, token) => {
          if (token?.tokenId && contractAddress) {
            const idOfToken = `${contractAddress.toLowerCase()}_${token.tokenId}`;
            const status =
              isNaN(Number(token.price ?? undefined)) && token.status === STATUS.NOW_ON_SALE
                ? STATUS.SUSPENSION
                : (token?.status as STATUS) || STATUS.SUSPENSION;
            initInfos[token.tokenId] = {
              status,
              id: token.uuid,
              price: token.price!,
              collectionUuid: collectionInfo.uuid,
              name: token.name || token.tokenName!,
              nameJa: token.nameJa || token.tokenName!,
              estimatedUsdPrice: !!token.price ? token.price * exchangeRate! : undefined,
              [MyShopCollectionsTokensQueryKey.Status]: status,
              [MyShopCollectionsTokensQueryKey.Price]: token.price!,
              [MyShopCollectionsTokensQueryKey.Order]: token.order!,
              [MyShopCollectionsTokensQueryKey.TokenId]: token.tokenId!,
              [MyShopCollectionsTokensQueryKey.TokenName]: token.tokenName!,
              [MyShopCollectionsTokensQueryKey.TokenMintTime]: token.tokenMintTime!,
              [MyShopCollectionsTokensQueryKey.Name]: token.name || token.tokenName!,
              [MyShopCollectionsTokensQueryKey.NameJa]: token.nameJa || token.tokenName!,
            };
            result.push(idOfToken);
          }
          return result;
        }, [] as string[]) || [];
      const tokensList = await getTokens({
        fetchPolicy: 'no-cache',
        variables: {
          first: 1000,
          where: { id_in: tokenIdsList },
          subgraphError: _SubgraphErrorPolicy_.Deny,
        },
        context: {
          blockchain: ContextAPIEnum.Subgraph,
          subgraphUrl: supportedNetworks[collectionInfo?.network || ''].subgraphUrl,
        },
      });
      const extraInfos = tokensList.data?.tokens.reduce((result, token) => {
        result[token.tokenID] = token;
        return result;
      }, {} as { [key: string]: TokenQuery['token'] });

      const newRows: MintedNFTInDataGrid[] = await Promise.all(
        myShopCollectionTokens?.map(async (token) => {
          const metadata = await getNFTMetadata(collectionInfo?.uuid!, extraInfos?.[token.tokenId!], ipfsGateways);
          const info = initInfos[token.tokenId!];
          return {
            ...metadata,
            ...info,
            status: info.status,
            url: metadata.metadataContent.image,
            contractAddress: metadata.ownerAddress,
          };
        }) || []
      );
      setRows(newRows);
      setLoadingTokensList(false);
    },
  });

  // Handle Edit Price Dialog
  const handleOpenEditPriceDialog = () => {
    handleCloseMenu();
    setOpenEditPriceDialog(true);
  };
  const handleCloseEditPriceDialog = () => {
    setOpenEditPriceDialog(false);
  };

  // Handle Edit Collection Name Dialog
  const onOpenEditNFTName = () => {
    setAnchorEl(null);
    setOpenEditNFTNameDialog(true);
  };

  const onCloseEditCollectionName = () => {
    setOpenEditNFTNameDialog(false);
  };

  const initializeTokensPrice = useCallback(
    (id: string, callback?: (currentToken: MintedNFTInDataGrid) => void, newTokenList?: MintedNFTInDataGrid[]) => {
      const currentToken = (newTokenList || rows || []).find((item) => item.id === id);

      if (currentToken) {
        if (callback) {
          callback(currentToken);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, loadingMyShopCollection]
  );

  const handleClickAction = useCallback(
    (row: MintedNFTInDataGrid): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        if (row[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SOLD_OUT) {
          setAnchorEl(event.currentTarget);
          setSelectedToken(row);
        } else {
          showErrorByKey('my_shop.message.already_sold_out');
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const checkStatusNft = useCallback(
    (status: string) => {
      if (status === NFT_STATUS.ON_SALE) {
        return <Chip label={t('on_sale')} color="success" />;
      } else {
        return <Chip label={t('unsold')} color="error" />;
      }
    },
    [t]
  );

  const handleStatus = useCallback(
    async (mintedNFT: MintedNFTInDataGrid | undefined) => {
      try {
        if (!mintedNFT) return;
        const status = mintedNFT[MyShopCollectionsTokensQueryKey.Status];
        const isForSale = status === STATUS.NOW_ON_SALE;
        const soldOut = status === STATUS.SOLD_OUT;
        if (soldOut) {
          showErrorByKey('my_shop.message.already_sold_out');
          return;
        }
        if (typeof mintedNFT[MyShopCollectionsTokensQueryKey.Price] !== 'number') {
          showErrorByKey('my_shop.message.need_set_price');
          return;
        }
        if (!isForSale && mintedNFT[MyShopCollectionsTokensQueryKey.Price] !== 0 && !!myShop?.publish) {
          const notRegistered = await show({
            description: 'payment_method_required.nft_has_price',
          });
          if (notRegistered) {
            return;
          }
        }
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              myShopCollectionUuid: myShopCollection?.uuid!,
              status: isForSale ? STATUS.SUSPENSION : STATUS.NOW_ON_SALE,
              tokenId: mintedNFT[MyShopCollectionsTokensQueryKey.TokenId],
            },
          },
        });
        setSelectedToken((prevState) =>
          prevState
            ? {
                ...prevState,
                status: isForSale ? STATUS.SUSPENSION : STATUS.NOW_ON_SALE,
              }
            : undefined
        );
        onCloseNFTDialog();
        showSuccess('my_shop.message.update_successful');
      } catch (e: any) {
        showError(e);
        setLoadingTokensList(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId, myShopCollection.uuid, t]
  );

  const handleClickPrice = useCallback(
    (row: MintedNFTInDataGrid) => () => {
      if (row[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SOLD_OUT) {
        initializeTokensPrice(row?.id || '', (currentToken) => {
          setSelectedToken(currentToken);
        });
        handleOpenEditPriceDialog();
      } else {
        showErrorByKey('my_shop.message.already_sold_out');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, initializeTokensPrice]
  );

  const existingColumns: GridColDef<MintedNFTInDataGrid>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage isNFT src={value || '/default/favicon-shop.png'} onClick={() => openNFTDialog(row.id)} />
            </Box>
          );
        },
      },
      {
        width: 100,
        editable: true,
        sortable: false,
        resizable: false,
        headerName: t('my_shop.order_index'),
        field: MyShopCollectionsTokensQueryKey.Order,
        renderEditCell: renderSelectEditInputCell,
      },
      {
        headerName: t('status'),
        width: JSON.parse(columnsSize).status || 125,
        field: MyShopCollectionsTokensQueryKey.Status,
        renderCell: ({ row }) => (
          <>
            {row[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT ? (
              <Chip
                label={`${collectionStatus(t)[row[MyShopCollectionsTokensQueryKey.Status] as STATUS]?.title || ''}`}
                color={CHIP_COLOR[row[MyShopCollectionsTokensQueryKey.Status]]}
              />
            ) : (
              <Select
                variant="standard"
                disableUnderline
                fullWidth
                value={
                  row[MyShopCollectionsTokensQueryKey.Status] === STATUS.NOW_ON_SALE
                    ? NFT_STATUS.ON_SALE
                    : NFT_STATUS.UNSOLD
                }
                onChange={() => handleStatus(row)}
              >
                {Object.values(NFT_STATUS).map((status) => (
                  <MenuItem key={status} value={status}>
                    {checkStatusNft(status)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        ),
      },
      {
        headerName: t('price'),
        width: JSON.parse(columnsSize).price || 100,
        field: MyShopCollectionsTokensQueryKey.Price,
        renderCell({ row }: { row: MintedNFTInDataGrid }) {
          const baseCurrency = myShop?.paymentMethod?.baseCurrency;
          const basePrice = row[MyShopCollectionsTokensQueryKey.Price];
          return (
            <>
              {!!basePrice ? (
                priceWithSymbol(basePrice, baseCurrency || Currency.Jpy)
              ) : row[MyShopCollectionsTokensQueryKey.Price] === 0 ? (
                <Chip variant="filled" color="success" label={t('free')} />
              ) : (
                <Typography className={classes.noPriceSet} color="red" onClick={handleClickPrice(row)}>
                  {t('no_price_set')}
                </Typography>
              )}
            </>
          );
        },
      },
      ...(!!myShop.siteSetting?.showPriceUsd
        ? [
            {
              sortable: false,
              field: 'estimatedUsdPrice',
              headerName: t('my_shop.est_usd_price'),
              width: JSON.parse(columnsSize).estimatedUsdPrice || 180,
              renderCell({ row }: { row: MintedNFTInDataGrid }) {
                const basePrice = row.price;
                const estimatedUsdPrice = row.estimatedUsdPrice;
                return (
                  <>
                    {!!basePrice ? (
                      `≈ ${priceWithSymbol(estimatedUsdPrice, Currency.Usd, 2)}`
                    ) : row[MyShopCollectionsTokensQueryKey.Price] === 0 ? (
                      <Chip variant="filled" color="success" label={t('free')} />
                    ) : (
                      <Typography color="red">{t('no_price_set')}</Typography>
                    )}
                  </>
                );
              },
            },
          ]
        : []),
      {
        field: MyShopCollectionsTokensQueryKey.Name,
        headerName: t('my_shop.nft_caption_en'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MyShopCollectionsTokensQueryKey.NameJa,
        headerName: t('my_shop.nft_caption_ja'),
        width: JSON.parse(columnsSize).nameJa || 150,
      },
      {
        headerName: t('my_shop.nft_name'),
        field: MyShopCollectionsTokensQueryKey.TokenName,
        width: JSON.parse(columnsSize).originalName || 150,
      },
      {
        headerName: t('token_id'),
        width: JSON.parse(columnsSize).tokenId || 150,
        field: MyShopCollectionsTokensQueryKey.TokenId,
      },
      {
        sortable: false,
        field: 'ownerAddress',
        headerName: t('owner_address'),
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                {truncateEthAddress(formattedValue)}
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 150,
      },
      {
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        field: MyShopCollectionsTokensQueryKey.TokenMintTime,
        valueFormatter: ({ value }) => (value ? moment.unix(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        sortable: false,
        type: 'actions',
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <>
            <IconButton aria-hidden="false" onClick={handleClickAction(row)}>
              <MoreVertIcon />
            </IconButton>
          </>,
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShop?.paymentMethod?.baseCurrency, t, handleClickPrice, handleClickAction, handleStatus]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSort = async (value: any) => {
    const sortBy = value.sortBy;
    const orderBy = value.orderBy;
    updateTokensQuery({ sortBy, orderBy });
    if (sortBy !== myShopCollection?.sortBy || orderBy !== myShopCollection?.orderBy) {
      await updateMyShopCollection({
        variables: {
          input: { uuid: myShopCollection?.uuid || '', sortBy, orderBy },
        },
      });
    }
  };

  // Handle NFT Dialog
  const openNFTDialog = useCallback(
    (id: string) => {
      setIsOpenNFTDialog(true);
      initializeTokensPrice(id, (currentToken) => {
        setSelectedToken(currentToken);
      });
    },
    [initializeTokensPrice]
  );

  const onCloseNFTDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const handleEditNFTName = async (data: IEditNFTNameField) => {
    try {
      if (selectedToken) {
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              ...data,
              myShopCollectionUuid: myShopCollection?.uuid!,
              tokenId: selectedToken[MyShopCollectionsTokensQueryKey.TokenId],
            },
          },
        });
        setSelectedToken((newState) =>
          newState ? { ...newState, name: data.name || '', nameJa: data.nameJa || '' } : undefined
        );
        showSuccess('toast_message.update_caption_successfully');
        setOpenEditNFTNameDialog(false);
      }
    } catch (err: any) {
      showError(err);
      setLoadingTokensList(false);
    }
  };

  const onSavePriceNFT = useCallback(
    async (newPrice: number) => {
      try {
        setLoadingTokensList(true);
        await setMyShopCollectionToken({
          variables: {
            input: {
              price: newPrice,
              myShopCollectionUuid: myShopCollection?.uuid!,
              tokenId: selectedToken?.[MyShopCollectionsTokensQueryKey.TokenId]!,
            },
          },
        });
        handleCloseEditPriceDialog();
        setSelectedToken((prevState) => {
          if (!prevState) {
            return prevState;
          }
          return {
            ...prevState,
            price: newPrice,
            [MyShopCollectionsTokensQueryKey.Price]: newPrice,
          };
        });
        showSuccess('my_shop.message.update_successful');
      } catch (e: any) {
        setLoadingTokensList(false);
        showError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shopId, selectedToken, myShopCollection.uuid, t]
  );

  const handleDataGridStatus = () => {
    handleStatus(selectedToken);
    setAnchorEl(null);
  };

  const handleProcessRowUpdate = async (newRow: MintedNFTInDataGrid, oldRow: MintedNFTInDataGrid) => {
    if (oldRow[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT) {
      showErrorByKey('my_shop.message.already_sold_out');
      return oldRow;
    }
    if (newRow[MyShopCollectionsTokensQueryKey.Order] === oldRow[MyShopCollectionsTokensQueryKey.Order]) {
      return newRow;
    }
    setLoadingTokensList(true);
    await setMyShopCollectionToken({
      variables: {
        input: {
          myShopCollectionUuid: myShopCollection?.uuid!,
          tokenId: newRow[MyShopCollectionsTokensQueryKey.TokenId],
          order: newRow[MyShopCollectionsTokensQueryKey.Order]
            ? parseFloat(newRow[MyShopCollectionsTokensQueryKey.Order]?.toString())
            : null,
        },
      },
    });
    handleCloseEditPriceDialog();
    return newRow;
  };

  const isLoading = fetchingTokens || loadingTokensList;

  return (
    <Box className={classes.wrapper}>
      <ListTable
        isMenu
        noBorder
        rows={rows}
        isShopScreen
        pagination={false}
        isLoading={isLoading}
        search={tokensQuery.searchText}
        tableName="shop_collection_existing_nft"
        onlyMode={VIEW_MODE.LIST}
        columns={existingColumns}
        disableRowSelectionOnClick
        noDataProps={{
          title: t('create_new_nft'),
          description: t('add_nft_to_minted_shop'),
          buttonTitle: t('add_nft'),
          onClick: onOpenAddNFT,
        }}
        onRowUpdate={handleProcessRowUpdate}
        searchLabel={t('my_shop.nft_name')}
        noRowsMessage={t('my_shop.message.no_nft')}
        onSearch={(v) => updateTokensQuery({ page: 1, searchText: v || '' })}
        getRowClassName={(params) =>
          params.row[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT ? 'disabled' : ''
        }
        sort={{
          sortBy: tokensQuery.sortBy,
          orderBy: tokensQuery.orderBy,
        }}
        onSort={handleSort}
      />
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            openNFTDialog(selectedToken?.id || '');
            setAnchorEl(null);
          }}
        >
          {t('my_shop.show_detail')}
        </MenuItem>
        <MenuItem onClick={() => handleOpenEditPriceDialog()}>{t('edit_price')}</MenuItem>
        <MenuItem onClick={onOpenEditNFTName}>{t('my_shop.edit_nft_name')}</MenuItem>
        <MenuItem onClick={handleDataGridStatus}>
          {selectedToken?.[MyShopCollectionsTokensQueryKey.Status] !== STATUS.SUSPENSION ? t('unsold') : t('on_sale')}
        </MenuItem>
      </Menu>
      <EditNameDialog
        open={openEditNFTNameDialog}
        title={t('my_shop.edit_nft_name')}
        defaultName={selectedToken?.[MyShopCollectionsTokensQueryKey.Name]!}
        defaultNameJa={selectedToken?.[MyShopCollectionsTokensQueryKey.NameJa]!}
        onEdit={handleEditNFTName}
        onClose={onCloseEditCollectionName}
      />
      <MintedNFTDialog
        open={isOpenNFTDialog}
        isShopCollectionScreen
        nftInfo={selectedToken!}
        collectionInfo={collectionInfo!}
        onEditNFTName={handleEditNFTName}
        onOpenPriceDialog={handleOpenEditPriceDialog}
        actions={[
          <Box key="1">
            <Button
              variant="outlined"
              sx={{ float: 'right' }}
              disabled={
                isNaN(Number(selectedToken?.[MyShopCollectionsTokensQueryKey.Price] ?? undefined)) ||
                selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT
              }
              color={
                selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION ? 'primary' : 'error'
              }
              onClick={handleDataGridStatus}
            >
              {selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SOLD_OUT
                ? t('my_shop.sold_out')
                : selectedToken?.[MyShopCollectionsTokensQueryKey.Status] === STATUS.SUSPENSION
                ? t('on_sale')
                : t('unsold')}
            </Button>
          </Box>,
        ]}
        onClose={onCloseNFTDialog}
      />
      <EditPriceDialog
        rowData={selectedToken}
        open={openEditPriceDialog}
        onSavePrice={onSavePriceNFT}
        onClose={handleCloseEditPriceDialog}
      />
    </Box>
  );
};

export default ExistingNFTList;
